import {TranslationController} from "../controller/menager";
import {DATA_OBJECT} from "../controller/dataController";

export function OrderStatusesHelper() {
    this.statuses = {
        revert: {
            id: "revert",
            bg: "#d6af57",
            font: "#fff",
            label: "Zwrot"
        },
        pending: {
            id: "pending",
            bg: "#d6af57",
            font: "#fff",
            label: "Aktywne"
        },
        process: {
            id: "process",
            bg: "#d6af57",
            font: "#fff",
            label: "W realizacji"
        },
        partialProcess: {
            id: "process",
            bg: "#d6af57",
            font: "#fff",
            label: "W częsciowej realizacji"
        },
        completed: {
            id: "completed",
            bg: "#3aa85b",
            font: "#fff",
            label: "Zrealizowane",
            maskLabel: "Aktywne"
        },
        success: {
            id: "success",
            bg: "#3aa85b",
            font: "#fff",
            label: "Sukces",
        },
        invoiced: {
            id: "invoiced",
            bg: "#3aa85b",
            font: "#fff",
            label: "Zafakturowane"
        },
        null: {
            id: "null",
            bg: "#8b0000",
            font: "#fff",
            label: "Brak danych"
        },
        waiting: {
            id: "null",
            bg: "#8b0000",
            font: "#fff",
            label: (DATA_OBJECT["user"] && DATA_OBJECT["user"].login === "root") ? "Oczekuje" : "Brak danych"
        },
        IN_PROGRESS: {
            id: "in_progress",
            bg: "#d6af57",
            font: "#fff",
            label: 'W realizacji'
        },
        WAITING: {
            id: "waiting",
            bg: "#8b0000",
            font: "#fff",
            label: "Oczekuje",
        },
        COMPLETED: {
            id: "completed",
            bg: "#3aa85b",
            font: "#fff",
            label: "Zrealizowane",
        },
        not_applicable: {
            id: "null",
            bg: "#a1a1a1",
            font: "#fff",
            label: "Nie dotyczy"
        },
        complaintNew: {
            id: "complaintNew",
            bg: "#d6af57",
            font: "#fff",
            label: "Nowe"
        },
        complaintParlourNew: {
            id: "complaintParlourNew",
            bg: "#d6af57",
            font: "#fff",
            label: "Przyjęta"
        },
        complaintProgress: {
            id: "complaintProgress",
            bg: "#3969c7",
            font: "#fff",
            label: "W toku"
        },
        complaintAccepted: {
            id: "complaintAccepted",
            bg: "#3aa85b",
            font: "#fff",
            label: "Uznane"
        },
        complaintParlourAccepted: {
            id: "complaintParlourAccepted",
            bg: "#3aa85b",
            font: "#fff",
            label: "Zaakceptowana"
        },
        complaintRepair: {
            id: "complaintRepair",
            bg: "#3969c7",
            font: "#fff",
            label: "Naprawa"
        },
        complaintChange: {
            id: "complaintChange",
            bg: "#3969c7",
            font: "#fff",
            label: "Wymiana"
        },
        handOverForRepair: {
            id: "handOverForRepair",
            bg: "#3969c7",
            font: "#fff",
            label: "Przekazano do naprawy"
        },
        handOverFromRepair: {
            id: "handOverFromRepair",
            bg: "#3969c7",
            font: "#fff",
            label: "Przyjęto z naprawy"
        },
        customerNotified: {
            id: "customerNotified",
            bg: "#3969c7",
            font: "#fff",
            label: "Klient powiadomiony"
        },
        complaintEnd: {
            id: "complaintEnd",
            bg: "#3aa85b",
            font: "#fff",
            label: "Reklamacja zakończona"
        },
        complaintRefund: {
            id: "complaintRefund",
            bg: "#3969c7",
            font: "#fff",
            label: "Zwrot pieniędzy/karta podarunkowa"
        },
        complaintRejected: {
            id: "complaintRejected",
            bg: "#8b0000",
            font: "#fff",
            label: "Nieuznana"
        },
        complaintSubRejected: {
            id: "complaintRejected",
            bg: "#8b0000",
            font: "#fff",
            label: "Nieuznana"
        },
        complaintAnswer: {
            id: "complaintAnswer",
            bg: "#3969c7",
            font: "#fff",
            label: "Odpowiedź"
        },
        complaintHandOverToExpert: {
            id: "complaintHandOverToExpert",
            bg: "#3969c7",
            font: "#fff",
            label: "Przekazano"
        },
        partialSended: {
            id: "partialSended",
            bg: "#d6af57",
            font: "#fff",
            label: "Wysłano częściowo"
        },
        partialSendedToCustomer: {
            id: "partialSendedToCustomer",
            bg: "#d6af57",
            font: "#fff",
            label: "Wysłano częściowo do klienta",
        },
        partialSendedToParlour: {
            id: "partialSendedToParlour",
            bg: "#d6af57",
            font: "#fff",
            label: "Wysłano częściowo do salonu"
        },
        new: {
            id: "new",
            bg: "#3969c7",
            font: "#fff",
            label: "Przydzielono"
        },
        active: {
            id: "active",
            bg: "#d6af57",
            font: "#fff",
            label: "Aktywne"
        },
        notSend: {
            id: "notSend",
            bg: "#d6af57",
            font: "#fff",
            label: "Nie wysłano"
        },
        assigned: {
            id: "assigned",
            bg: "#d6af57",
            font: "#fff",
            label: "Przydzielono"
        },
        readyForClaimByCustomer: {
            id: "readyForClaimByCustomer",
            bg: "#3969c7",
            font: "#fff",
            label: "Potwierdzono odebranie w salonie"
        },
        claimedByCustomer: {
            id: "claimedByCustomer",
            bg: "#3aa85b",
            font: "#fff",
            label: "Klient odebrał w salonie"
        },
        send: {
            id: "send",
            bg: "#3aa85b",
            font: "#fff",
            label: "Wysłano"
        },
        sendToCustomer: {
            id: "sendToCustomer",
            bg: "#3aa85b",
            font: "#fff",
            label: "Wysłano do klienta"
        },
        sendToParlour: {
            id: "sendToParlour",
            bg: "#3aa85b",
            font: "#fff",
            label: "Wysłano do salonu"
        },
        accepted: {
            id: "accepted",
            bg: "#d6af57",
            font: "#fff",
            label: "Zaakceptowoano"
        },
        acceptedInChunk: {
            id: "acceptedInChunk",
            bg: "#d6af57",
            font: "#fff",
            label: "Zaakceptowano częściowo"
        },
        rejected: {
            id: "rejected",
            bg: "#8b0000",
            font: "#fff",
            label: "Odrzucono"
        },
        canceled: {
            id: "canceled",
            bg: "#3a3a3a",
            font: "#fff",
            label: "Anulowano"
        },
        stopped: {
            id: "stopped",
            bg: "#8b0000",
            font: "#fff",
            label: "Zatrzymane"
        },
        notClaimed: {
            id: "notClaimed",
            bg: "#a1a1a1",
            font: "#fff",
            label: "Nie odebrano"
        },
        notSolved: {
            id: "notSolved",
            bg: "#8b0000",
            font: "#fff",
            label: "Potrzebuje operatora"
        },
        notAssigned: {
            id: "notAssigned",
            bg: "#8b0000",
            font: "#fff",
            label: "Nie przydzielono"
        },
        error: {
            id: "error",
            bg: "#8b0000",
            font: "#fff",
            label: "Błąd"
        },
        new_complaint: {
            id: "new_complaint",
            bg: "#d6af57",
            font: "#fff",
            label: "Nowe"
        },
        new_payment: {
            id: "new_complaint",
            bg: "#d6af57",
            font: "#fff",
            label: "Nowa"
        },
        close_complaint: {
            id: "close_complaint",
            bg: "#3aa85b",
            font: "#fff",
            label: "Zamknięte"
        },
        complaintExpert: {
            id: "complaintExpert",
            bg: "#3969c7",
            font: "#fff",
            label: "Rzeczoznawca"
        },
        complaintAppeal: {
            id: "complaintAppeal",
            bg: "#a80000",
            font: "#fff",
            label: "Odwołanie"
        },
        solved: {
            id: "solved",
            bg: "#3aa85b",
            font: "#fff",
            label: "Zakończona"
        },
        paused: {
            id: "paused",
            bg: "#d6af57",
            font: "#fff",
            label: "Wstrzymane"
        },
        paid: {
            id: "paid",
            bg: "#d6af57",
            font: "#fff",
            label: "Opłacona"
        },
        W: {
            id: "W",
            bg: "#3aa85b",
            font: "#fff",
            label: "Wydane"
        },
        A: {
            id: "A",
            bg: "#d6af57",
            font: "#fff",
            label: "Anulowane"
        },
        D: {
            id: "D",
            bg: "#d6af57",
            font: "#fff",
            label: "Do analizy"
        },
        C: {
            id: "C",
            bg: "#d6af57",
            font: "#fff",
            label: "Czeka"
        },
        T: {
            id: "T",
            bg: "#d6af57",
            font: "#fff",
            label: "W Toku"
        },
        Z: {
            id: "Z",
            bg: "#3aa85b",
            font: "#fff",
            label: "Zrealizowane"
        },
        S: {
            id: "S",
            bg: "#d6af57",
            font: "#fff",
            label: "Wstrzymane"
        },
        item_A: {
            id: "item_A",
            bg: "#1b9aff",
            font: "#fff",
            label: "Zarezerwowano"
        },
        item_B: {
            id: "item_B",
            bg: "#a80000",
            font: "#fff",
            label: "---"
        },
        defectRejected: {
            id: "defectRejected",
            bg: "#a80000",
            font: "#fff",
            label: "Odrzucono"
        },
        defectAccepted: {
            id: "defectAccepted",
            bg: "#3aa85b",
            font: "#fff",
            label: "Zaakceptowano"
        },
        defectNew: {
            id: "defectNew",
            bg: "#d6af57",
            font: "#fff",
            label: "Przyjęta"
        },
        defectRepair: {
            id: "defectRepair",
            bg: "#3aa85b",
            font: "#fff",
            label: "Naprawa"
        },
        defectChange: {
            id: "defectChange",
            bg: "#3aa85b",
            font: "#fff",
            label: "Wymiana"
        },
        defectRefund: {
            id: "defectRefund",
            bg: "#3aa85b",
            font: "#fff",
            label: "Zwrot pieniędzy/karta podarunkowa"
        },
        defectPriceChange: {
            id: "defectPriceChange",
            bg: "#3aa85b",
            font: "#fff",
            label: "Zmiana ceny"
        },
        defectShift: {
            id: "defectShift",
            bg: "#3aa85b",
            font: "#fff",
            label: "Przesunięcie"
        },
        defectEnd: {
            id: "defectEnd",
            bg: "#000",
            font: "#fff",
            label: "Zakończono"
        },
        errored: {
            id: "errored",
            bg: "#a80000",
            font: "#fff",
            label: "Błąd"
        },
        in_progress: {
            id: "in_progress",
            bg: "#3aa85b",
            font: "#fff",
            label: "W realizacji"
        },
        error_export: {
            id: "error_export",
            bg: "#a80000",
            font: "#fff",
            label: "Błąd eksportu"
        },
        incorrect_data: {
            id: "incorrect_data",
            bg: "#a80000",
            font: "#fff",
            label: "Błędne dane"
        },
        out_of_stock: {
            id: "out_of_stock",
            bg: "#a80000",
            font: "#fff",
            label: "Brak w magazynie"
        },
    };

    this.baseStatuses = [
        this.statuses.active,
        this.statuses.completed,
        this.statuses.invoiced
    ]

    this.gateStatuses = [
        this.statuses.completed,
        this.statuses.waiting,
        this.statuses.process,
        this.statuses.stopped,
        this.statuses.canceled,
        this.statuses.error
    ]

    this.completationStatuses = [
        this.statuses.solved,
        this.statuses.notSolved,
        this.statuses.assigned,
        this.statuses.accepted,
        this.statuses.acceptedInChunk,
        this.statuses.send,
        this.statuses.partialSended
    ]

    this.synchroStatuses = [
        this.statuses.solved,
        this.statuses.process,
    ]

    this.shippingStatuses = [
        this.statuses.send,
        this.statuses.notSend,
        this.statuses.readyForClaimByCustomer,
        this.statuses.claimedByCustomer,
        this.statuses.partialSended,
        this.statuses.partialSendedToCustomer,
        this.statuses.partialSendedToParlour,
        this.statuses.sendToCustomer,
        this.statuses.sendToParlour,
        this.statuses.notClaimed,
        this.statuses.paused
    ];

    this.shippingStatusesUkr = [
        this.statuses.notSend,
        this.statuses.sendToCustomer,
    ];

    this.shippingStatusesPrib = [
        this.statuses.notSend,
        this.statuses.sendToCustomer,
        this.statuses.sendToParlour,
    ];

    this.complaintStatuses = [
        this.statuses.complaintAccepted,
        this.statuses.complaintRejected,
        this.statuses.complaintProgress,
    ]

    this.getColor = status => this.statuses[status] ? this.statuses[status].bg : "#c7c7c7";
    this.getFontColor = status => this.statuses[status] ? this.statuses[status].font : "#000";
    this.getLabel = status => this.statuses[status] ? TranslationController.translate(this.statuses[status].label) : status;
}
