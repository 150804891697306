import React, { useState } from 'react';
import EsotiqInput from "../../components/EsotiqInput";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import pl from 'date-fns/locale/en-GB';
import { Form, Modal } from 'semantic-ui-react';
import Button from '../../components/Button';
import { formatDate } from '../../helpers/dateHelper';
import { api } from '../../lib/axios';
import SnackBar from '../../components/Snack';

const CreateK2Complaint = ({isOpen, handleClose}) => {
    const [externalDocumentNumber, setExternalDocumentNumber] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [createdAt, setCreatedAt] = useState(new Date());
    const [warehouse, setWarehouse] = useState('');
    const [invoiceCorrectionAmount, setInvoiceCorrectionAmount] = useState('');
    const [complaintNo, setComplaintNo] = useState('');
    const [orderId, setOrderId] = useState('');

    const handleSubmit = async () => {
        const invoiceCorrectionDetails = {
            isError: false,
            errorMessage: "",
            externalDocumentNumber,
            invoiceNumber,
            externalConnectedInvoiceNumber: "2382091",
            pdf: `https://integrator.esotiqhenderson.com:443/pos/getdocument/${externalDocumentNumber}/25928c450fae9327a4a7d75b3d503be0/014b4eb49ef800000000000000000035?InstanceID=rcvb`,
            connectedPdf: "https://integrator.esotiqhenderson.com:443/pos/getdocument/2382091/f43a3ae309d0c0b806a30133bc6fe357?InstanceID=rcvb",
            createdAt: createdAt.getTime(),
            warehouse,
            invoiceCorrectionAmount: parseFloat(invoiceCorrectionAmount),
            complaintNo,
            orderId: parseInt(orderId)
        }


        const res = await api().post('/api/corrections/insert', {
            invoiceCorrectionDetails
        })

        if (res?.data?.error) {
            return SnackBar("Wystąpił błąd podczas tworzenia zwrotu.", "error");
        }

        handleClose();

        SnackBar("Zwrot został utworzony.", "success");
        window.location.reload();
    }

    return (
        <Modal dimmer={"inverted"} size={"small"} open={isOpen}>
            <Modal.Header>Tworzenie zwrotu</Modal.Header>
            <Modal.Content>
                <EsotiqInput
                    label="Zewnętrzny numer dokumentu"
                    placeholder=""
                    value={externalDocumentNumber}
                    onChange={v => setExternalDocumentNumber(v)}
                />
                <EsotiqInput
                    label="Numer faktury"
                    placeholder=""
                    value={invoiceNumber}
                    onChange={v => setInvoiceNumber(v)}
                />
                <EsotiqInput
                    label="Sklep"
                    placeholder=""
                    value={warehouse}
                    onChange={v => setWarehouse(v)}
                />
                <EsotiqInput
                    label="Wartość zwrotu"
                    placeholder=""
                    value={invoiceCorrectionAmount}
                    onChange={v => setInvoiceCorrectionAmount(v)}
                />
                <EsotiqInput
                    label="Numer zwrotu"
                    placeholder=""
                    value={complaintNo}
                    onChange={v => setComplaintNo(v)}
                />
                <EsotiqInput
                    label="Numer zamówienia"
                    placeholder=""
                    value={orderId}
                    onChange={v => setOrderId(v)}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
                    <KeyboardDateTimePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Utworzono"
                        format="dd-MM-yyyy HH:mm"
                        value={createdAt}
                        onChange={(date) => {
                            const filterValue = formatDate(date).dateNowSystem;

                            setCreatedAt(date);
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={handleClose}>Zamknij</Button>
                <Button positive onClick={handleSubmit}>Zapisz</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default CreateK2Complaint;