import React from "react";
import {TranslationController} from "../../../controller/menager";
import {formatDate} from "../../../helpers/dateHelper";
import Button from "../../../components/Button";
import {DATA_OBJECT} from "../../../controller/dataController";
import {api, STATIC_URL} from "../../../lib/axios";
import SnackBar from "../../../components/Snack";
import Confirm from "../../../components/Confirm";
import {generatePickup} from "../../../helpers/generatePickup";
import DownloadPickupFileButton from "../../../components/DownloadPickupFileButton";

export default class OrderServicesCourierWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            row: props.row
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.row !== this.props.row) {
            this.setState({row: this.props.row});
        }
    }

    render() {
        const row = this.state.row;

        return (
            <div>
                {row.pickupNumber &&
                    <div style={{
                        fontWeight: 600,
                        marginBottom: 15
                    }}>
                        <div style={{
                            fontWeight: 600,
                            color: "#848484"
                        }}>{TranslationController.translate("Numer listu przewozowego")}:</div>
                        {row.pickupNumber}
                    </div>
                }

                {row.pickupCreateDate &&
                    <div style={{
                        fontWeight: 600,
                        marginBottom: 15
                    }}>
                        <div style={{
                            fontWeight: 600,
                            color: "#848484"
                        }}>{TranslationController.translate("Data utworzenia")}:</div>
                        {formatDate(row.pickupCreateDate).dateTimeSystem}
                    </div>
                }

                {(row.pickupFile || row.pickupNumber) &&
                    <div style={{
                        marginTop: 15
                    }}>
                        <DownloadPickupFileButton row={row}/>
                    </div>
                }

                <React.Fragment>
                    {(row.parlour === "E99"
                            ? ((!row.pickupFile && !row.pickupNumber) || row.provider === 'gls')
                            : (row.status === "accepted" || row.status === "send") && ((!row.pickupFile && !row.pickupNumber) || row.provider === 'gls')) &&
                        <div style={{
                            marginTop: 15
                        }}>
                            <Button onClick={() => {
                                return Confirm(
                                    "Czy na pewno chcesz wygenerować nowy list przewozowy?",
                                    "",
                                    async () => {
                                        this.props.toggleLoader(true);

                                        await generatePickup(row);

                                        this.props.toggleLoader(false);
                                        this.setState({
                                            refresh: new Date().getTime(),
                                        })
                                        this.props.toggleLoader(false);
                                        this.props.tableRefresh();
                                    })
                            }
                            }>{row.pickupNumber
                                ? TranslationController.translate("Generuj list przewozowy ponownie")
                                : TranslationController.translate("Generuj list przewozowy")}</Button>
                        </div>
                    }
                </React.Fragment>
            </div>
        )
    }
}