import React from "react";
import {Dimmer, Loader} from "semantic-ui-react";
import ReactDOM from "react-dom";

export default class GlobalDimmer {
    static domElement;

    static Set() {
        this.domElement = document.getElementById("modal-root");
        return ReactDOM.render(DimmerProvider(), this.domElement);
    }

    static Unset() {
        ReactDOM.unmountComponentAtNode(this.domElement);
    }
}

function DimmerProvider() {
    return (
        <Dimmer active inverted style={{
            position: "fixed",
            zIndex: 99999
        }}>
            <Loader size='medium'>Proszę czekać...</Loader>
        </Dimmer>
    )
}