import axios from 'axios';
import {ControlPanelUrls} from "../api/urls";

const isDev = () => localStorage.getItem("api_type") && localStorage.getItem("api_type") === "dev";
const cpClientUrl = process.env.REACT_APP_PROD_API_CP_BASE_URL || `https://api_cp.esotiq.com`;
const cpDevClientUrl = process.env.REACT_APP_DEV_API_CP_BASE_URL || `https://api_dev.esotiq.com`;

const getApiUrl = (() => {
    return isDev() ? cpDevClientUrl : cpClientUrl;
})

export const STATIC_URL = `${getApiUrl()}/static/`;
export const UPLOADS_URL = `${getApiUrl()}/uploads/`;
const authConfig = (config) => {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;

        if (![ControlPanelUrls.SIGN_IN].includes(config.url) && !["https://service.esotiq.com/api/v1/nest"].includes(config.baseURL)) {
            config.params = { ...config.params, token };
        }
    }
    return config;
}
export const cpClient = axios.create({
    baseURL: cpClientUrl,
});

cpClient.interceptors.request.use(authConfig);
export const cpDevClient = axios.create({
    baseURL: cpDevClientUrl,
});

cpDevClient.interceptors.request.use(authConfig);
export const api2Client = axios.create({
    baseURL: process.env.REACT_APP_API2_BASE_URL || `https://api2.esotiq.com`,
});

export const serviceClient = axios.create({
    baseURL: process.env.REACT_APP_SERVICE_API_BASE_URL || "https://service.esotiq.com/api/v1",
});

export const notificationClient = axios.create({
    baseURL: process.env.REACT_APP_NOTIFICATION_API_BASE_URL || `https://api-notification.esotiq.com`,
});

export const controlPanelApi = axios.create({
    baseURL: process.env.REACT_APP_ESOTIQ_CONTROL_PANEL_API_BASE_URL || `https://service.esotiq.com/api/v1/nest`,
});

controlPanelApi.interceptors.request.use(authConfig);

export const parlourDetailsApi = axios.create({
    baseURL: process.env.REACT_APP_ESOTIQ_PARLOUR_DETAILS_SERIVCE_API_BASE_URL ?? 'https://esotiq-stage-parlourdet-app.azurewebsites.net'
})

parlourDetailsApi.interceptors.request.use(authConfig)

export const functionsApi = axios.create({
    baseURL: process.env.REACT_APP_AZ_FUNCTIONS_URL || "https://esotiq-stage-functions.azurewebsites.net/api"
})

functionsApi.interceptors.request.use(authConfig)

export const api = (second_api, forceUrl, useAuth = false, useService) => {
    if (forceUrl) {
        return axios.create({baseURL: forceUrl})
    }

    if (useService) {
        return serviceClient;
    }

    return isDev() ? cpDevClient : cpClient;
};

export const sendFile = (url, file, customData) => {
    const formData = new FormData();

    if (customData) {
        for (let [key, value] of Object.entries(customData)) {
            formData.append(key, value);
        }
    }

    formData.append("file", file);

    api().post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
};

export const baseXHR = () => {
    if(isDev()) {
        return cpDevClient;
    }
    return cpClient;
};
