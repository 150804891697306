import React from "react";
import {api} from "../../lib/axios";
import Container from "../../components/Container";
import {TranslationController} from "../../controller/menager";
import Breadcrumb from "../../components/Breadcrumb";
import {Segment, Menu, Button} from 'semantic-ui-react'
import SnackBar from "../../components/Snack";
import UITable from "../../lib/table-v2";
import CsvImport from "../../components/CsvImport";
import TableMenu from "../../components/TableMenu";
import {DATA_OBJECT} from "../../controller/dataController";
import GlobalDimmer from "../../components/GlobalDimmer";

export default class ParlourCommision extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            appliedFilters: null
        };
    }

    init() {
        return api().post("/api/parlours/commission/get")
            .then(r => {
                this.setState({data: r.data.data});
                return r.data.data
            })
    }

    render() {
        return (
            <div>
                <Container>
                    <React.Fragment>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `${TranslationController.translate("Zarządzanie prowizjami salonów")} ${this.state.dataCount ? `(${this.state.dataCount})` : ""}`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>
                    </React.Fragment>

                    <Menu className={"order-menu"} attached='top'>
                        <TableMenu item icon='wrench' simple>
                            <TableMenu.Menu>
                                <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>

                                <TableMenu.Divider />
                                <TableMenu.Header>Import</TableMenu.Header>
                                <TableMenu.Item>
                                    <CsvImport
                                        csvStructure={[
                                            {column: "id"},
                                            {column: "commission"},
                                        ]}
                                        onUpdate={(data) => {
                                            const update = data.filter(row => row.id && row.commission);
                                            GlobalDimmer.Set();

                                            return api().post("/api/parlours/commission/update", { update, user: DATA_OBJECT.user.login })
                                                .then((data) => {
                                                    if(data.error) {
                                                        SnackBar(data.error, "error");
                                                        GlobalDimmer.Unset();
                                                        return
                                                    }

                                                    setTimeout(() => {
                                                        SnackBar("Zaktualizowane poprawnie.")

                                                        this.orderTable.setState({
                                                            data: null
                                                        });

                                                        this.orderTable.init();

                                                        GlobalDimmer.Unset();
                                                    }, 4000)
                                                })
                                        }}
                                    />
                                </TableMenu.Item>
                            </TableMenu.Menu>
                        </TableMenu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <UITable
                            dataFetch={{
                                url: "/api/parlours/commission/get",
                                options: {pageSize: 300, currentPage: 1},
                            }}
                            ref={table => this.orderTable = table}
                            provider={this}
                            columns={[
                                {
                                    field: "id",
                                    label: "Salon",
                                },
                                {
                                    field: "commission",
                                    label: "Prowizja",
                                    render: row => row.commission
                                },
                            ]}
                        />
                    </Segment>
                </Container>
            </div>
        );
    }
}
