import SnackBar from "../components/Snack";

export const resolveTrackingUrlForProvider = (provider, pickupNumber) => {
    switch(provider) {
        case 'dpd':
            return `https://tracktrace.dpd.com.pl/parcelDetails?typ=1&p1=${pickupNumber}`;
        case 'gls':
            return `https://gls-group.eu/PL/pl/sledzenie-paczek?match=${pickupNumber}`;
        case 'inpost':
        case 'inpost_courier':
            return `https://inpost.pl/sledzenie-przesylek?number=${pickupNumber}`;
        case 'ruch':
            return `https://www.orlenpaczka.pl/sledz-paczke/?numer=${pickupNumber}`;
        default:
            return SnackBar('Nie można określić dostawcy', 'error');
    }
}