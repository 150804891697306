import React from 'react'
import Grid from '@material-ui/core/Grid';
import {api} from "../../../lib/axios";
import LinearProgress from '@material-ui/core/LinearProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import DataPresentation from "../../../components/DataPresentation";
import PaperTitle from "../../../components/PaperTitle";
import ActionPanel from "../../../components/ActionPanel";
import Breadcrumb from "../../../components/Breadcrumb";
import OrderStatus from "../OrderStatus";
import OrderCart from "./cart";
import {OrderStatusesHelper} from "../../../helpers/orderStatuses";
import OrderHistory from "./history";
import Button from "../../../components/Button";
import {Loader, Icon, Label, Form, Comment, Dimmer, Modal, Table, Accordion, Input, Message} from "semantic-ui-react";
import SnackBar from "../../../components/Snack";
import Confirm from "../../../components/Confirm";
import {DATA_OBJECT} from "../../../controller/dataController";
import {formatDate} from "../../../helpers/dateHelper";
import {TranslationController} from "../../../controller/menager";
import {PDFDownloadLink} from "@react-pdf/renderer";
import MultiDeliveryPDF from "./multiDeliveryPDF";
import EsotiqInput from "../../../components/EsotiqInput";
import DisplayPrice from "../../../components/display/DisplayPrice";
import {mailerVariants} from "../../../lib/mailerTemplates";
import Tooltip from "@material-ui/core/Tooltip";
import PaperComponent from "../../../components/Paper";
import OrderGrid from "./orderGrid";
import {jsonCopy} from "../../../helpers/lib";
import PersonalizedItems from "./personalizedItems";
import ReservationHistory from "./reservationHistory";

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: "10px 0" }}>
            {props.children}
        </Typography>
    );
}

class OrderPreview extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            orderId: null,
            shippingAdress: null,
            billingAdress: null,
            orderGrid: null,
            paymentAdds: null,
            tab: 0,
            orderData: props.location ? props.location.state : props.orderData,
            editState: false,
            shippingMethods: {},
            shippingMethodsList: [],
            availablePaymentMethods: null,
            availablePaymentMethodsList: [],
            addPaymentAmount: 0
        }
    }

    componentDidMount() {
        if (this.props.orderData) {
            this.state.orderId = this.props.orderData.id;
        }

        this.init();
    }

    getAvailablePaymentMethods() {
        this.state.shippingMethodsList.forEach(method => {
            if (method._id === this.state.orderData.shippingMethodId) {
                const paymentMethodsMap = {};

                method.availablePaymentMethods.forEach(e => paymentMethodsMap[e.id] = e);

                this.setState({
                    availablePaymentMethods: paymentMethodsMap ?? {},
                    availablePaymentMethodsList: method.availablePaymentMethods ?? []
                })
            }
        })
    }

    init() {
        return api().post("/api/system/shipping-methods/get", {limit: 99999, currentPage: 1})
            .then(r => {
                const dataMap = {};

                r.data.data.map(method => {
                    dataMap[method._id] = method;
                    dataMap[method.externalId] = method
                });

                this.setState({
                    shippingMethods: dataMap,
                    shippingMethodsList: r.data.data
                })
            })
            .then(() => {
                return new Promise(resolve => {
                    api().post("/api/orders/get", {orderId: this.state.orderId || this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 1]})
                        .then(r => {
                            this.setState({
                                orderData: r.data.order,
                                initOrderData: jsonCopy(r.data.order)
                            }, () => this.bindCorrectionDocument());


                            resolve(true)
                        })
                })
            })
            .then(() => this.getAvailablePaymentMethods())
            .then(() => {
                let isParlourOrder = false;

                if (this.state.orderData.id.toString().charAt(0) === "2") {
                    isParlourOrder = true;
                }

                if (this.state.orderData.id.toString().charAt(0) === "4") {
                    isParlourOrder = true;
                }

                if (this.state.orderData.additionalOptions && this.state.orderData.additionalOptions.forceWarehouse && this.state.orderData.additionalOptions.forceWarehouse === "k99") {
                    isParlourOrder = false;
                }

                if (this.state.orderData.additionalOptions && this.state.orderData.additionalOptions.forceWarehouse && this.state.orderData.additionalOptions.forceWarehouse === "x99") {
                    isParlourOrder = true;
                }

                if (this.state.orderData.additionalOptions && this.state.orderData.additionalOptions.forceWarehouse && this.state.orderData.additionalOptions.forceWarehouse === "salonowe") {
                    isParlourOrder = true;
                }

                this.setState({
                    isParlourOrder,
                    dimmer: false
                })
            })
    }

    bindCorrectionDocument() {
        return true;
        // api(true).post("/api/orders/bindCorrectionDocument",  {
        //     orderIds: [{orderId: this.state.orderData.id}]
        // })
        //     .then((r) => {
        //         if (r && r.data && r.data.result) {
        //             if (r.data.result[this.state.orderData.id]) {
        //                 this.state.orderData.erpCorrection = r.data.result[this.state.orderData.id];
        //
        //                 this.setState({
        //                     refresh: new Date().getTime()
        //                 })
        //             }
        //         }
        //     })
    }

    addComment() {
        if (!this.state.newCommentValue) {
            return SnackBar("Wprowadzona wartość nie może być pusta.", "error")
        }

        if (this.state.newCommentValue.length < 4) {
            return SnackBar("Wprowadzony tekst jest za krótki.", "error")
        }

        const complaint = this.state.orderData

        if (!complaint.comments) {
            complaint.comments = []
        }

        complaint.comments.push({
            value: this.state.newCommentValue,
            timestamp: new Date().getTime(),
            user: DATA_OBJECT.user_login,
            orderId: this.state.orderId || parseInt(this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 1])
        })

        api().post("/api/orders/comments/add", {
            value: this.state.newCommentValue,
            timestamp: new Date().getTime(),
            user: DATA_OBJECT.user_login,
            orderId: this.state.orderId || parseInt(this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 1])
        })
            .then(() => {
                this.setState({
                    newCommentValue: "",
                })

                SnackBar("Pomyślnie zaktualizowano.")
            })
    }

    removeComment(comment) {
        if (DATA_OBJECT.user_login !== comment.user) {
            return SnackBar("Możesz usuwać tylko własne komentarze", "error")
        }

        const complaint = this.state.orderData

        complaint.comments = complaint.comments.filter(comm => comm !== comment)

        api().post("/api/orders/comments/remove", {...comment})
            .then(() => {
                this.setState({
                    refresh: new Date().getTime(),
                })

                SnackBar("Pomyślnie zaktualizowano.")
            })
    }

    handleChangeTab(event, newValue) {
        this.setState({
            tab: newValue
        })
    }

    refresh() {
        return false;

        this.setState({refresh: new Date().getTime()})
    }

    hardRefresh(callback) {
        this.state.orderData = null;

        this.forceUpdate();

        return new Promise(resolve => {
            this.init()
                .then(() => {
                    if (callback) {
                        callback(this.state.orderData);
                    }

                    resolve();
                })
        })
    }

    handleOrderSave(order) {
        return api().post("/api/orders/update", {order, userId: DATA_OBJECT.user_login})
            .then(r => {
                this.state.initOrderData = {...this.state.initOrderData, ...order};

                this.setState({editState: false})

                if (r.data.error) {
                    return SnackBar(r.data.message, "error")
                }

                SnackBar("Zaktualizowano zamówienie");
            })
    }

    handleOrderCancel(order, preventEmail) {
        return Confirm("Czy na pewno chcesz anulować zamówienie?", null, () => {
            this.setState({
                dimmer: true
            })

            api().post("/api/orders/cancel", {order, userId: DATA_OBJECT.user_login, preventEmail})
                .then(r => {
                    if (r.data.error) {
                        SnackBar(r.data.message, "error");
                    } else {
                        SnackBar("Anulowano zamówienie");

                        this.hardRefresh()
                    }

                    this.setState({
                        dimmer: false
                    })
                })
        })
    }

    handleCopyOrder(order) {
        return Confirm("Czy na pewno chcesz skopiować zamówienie?", null, () => {
            api().post("/api/orders/copyOrder", {order, userId: DATA_OBJECT.user_login})
                .then(r => {
                    if (r.data.error) {
                        SnackBar(r.data.message, "error");
                    } else {
                        SnackBar("Skopiowano zamówienie");

                        this.hardRefresh()
                    }
                })
        })
    }

    handleOrderTransfer(order) {
        return Confirm("Czy na pewno chcesz przenieść zamówienie?", null, () => {
            api().post("/api/orders/transfer", {order, userId: DATA_OBJECT.user_login, warehouse: order.id.toString().charAt(0) === "2" ? "k99" : "x99"})
                .then(r => {
                    if (r.data.error) {
                        SnackBar(r.data.message, "error");
                    } else {
                        SnackBar("Zaktualizowano zamówienie");

                        this.hardRefresh()
                    }
                })
        })
    }

    handleWebFrontierRequest(order, status) {
        return Confirm("Czy na pewno chcesz wysłać komunikat do systemu Frontier?", null, () => {
            api().post("/api/orders/frontier/request", {
                orderId: order.id,
                status: status,
                userId: DATA_OBJECT.user_login
            })
                .then(result => {
                    if (result.data.error) {
                        SnackBar(result.data.message, "error");
                    } else {
                        SnackBar("Wysłano komunikat");
                    }
                })
        })
    }

    handleOrderTransferN32(order) {
        return Confirm("Czy na pewno chcesz przenieść zamówienie do N32?", null, () => {
            api().post("/api/orders/transfer", {order, userId: DATA_OBJECT.user_login, warehouse: "N32"})
                .then(r => {
                    if (r.data.error) {
                        SnackBar(r.data.message, "error");
                    } else {
                        SnackBar("Zaktualizowano zamówienie");

                        this.hardRefresh()
                    }
                })
        })
    }

    toggleCompltation(order) {
        this.setState({
            orderData: null
        });

        api().post("/api/orders/update", {order: {...order, ...{
                    completationStopped: order.completationStopped ? false : true,
                    userId: DATA_OBJECT.user_login
                }}})
            .then(r => {
                this.hardRefresh()

                if (r.data.error) {
                    return SnackBar(r.data.message, "error")
                }

                SnackBar("Zaktualizowano zamówienie");
            })
    }

    reCompltation(order) {
        // if (!order.statusSynchro) {
        //     return SnackBar("Nie możesz użyć ponownej kompletacji bo zamówienie jest w jej trakcie.", "error")
        // }
        //
        // if (order.statusSynchro === "process") {
        //     return SnackBar("Nie możesz użyć ponownej kompletacji bo zamówienie jest w jej trakcie.", "error")
        // }

        Confirm("Czy na pewno chcesz uruchomić ponowną kompletację? Przypisane zostaną tylko nowe towary.", "", () => {
            this.setState({
                dimmer: true
            })

            api().post("/api/orders/recompletation", {order})
                .then(r => {
                    SnackBar("Uruchomiono kompletację, proszę czekać.")

                    this.setState({
                        dimmer: false
                    })

                    if (r.data.error) {
                        SnackBar(r.data.message, "error")
                    } else {
                        SnackBar("Poprawnie przypisano towary.");

                        this.hardRefresh()
                    }
                    //this.init()
                })
        })
    }

    handleChangeSlug(order, newSlug) {
        return Confirm("Czy na pewno chcesz przenieść zamówienie?", null, () => {
            this.setState({
                dimmer: true
            })
            api().post("/api/orders/changeSlug", {order, newSlug, userId: DATA_OBJECT.user_login})
                .then(r => {
                    if (r.data.error) {
                        SnackBar("Nie można przenieść zamówienia", "error");
                    } else {
                        SnackBar("Zaktualizowano zamówienie");

                        this.hardRefresh()
                    }
                    this.setState({
                        dimmer: false
                    })
                }).catch(e => {
                    SnackBar("Błąd serwera", "error");
                    this.setState({
                        dimmer: false
                    })
                })
        })
    }

    render() {
        const {tab, editState} = this.state
        const props = this.props
        let orderData = this.state.orderData ? this.state.orderData : props.location ? props.location.state : props.orderData;
        const classes = {};
        return (
            orderData ? <div style={{backgroundColor: "#f4f4f4"}}>
                <ActionPanel
                    className={this.props.isFastPreview ? "fast-previw-order-menu" : null}
                    actions={[
                        {
                            icon: "perm_data_setting",
                            label: !this.state.editState ? "Edytuj zamówienie" : "Anuluj edycję",
                            onClick: () => {
                                this.setState({editState: !this.state.editState})
                            },
                            collapse: true
                        },
                        this.state.editState ? {
                            icon: "perm_data_setting",
                            label: "Zapisz",
                            onClick: () => this.handleOrderSave(orderData),
                            collapse: true
                        } : null,
                        {
                            icon: "perm_data_setting",
                            label: "Ponowna synchronizacja",
                            onClick: () => Confirm("Na pewno chcesz ponownie synchronizować to zamówienie?", null, () => this.handleOrderSave({
                                ...orderData,
                                statusSynchro: "process"
                            }).then(() => {
                                this.hardRefresh()
                            })),
                            collapse: true
                        },
                        ["canceled", "completed"].includes(orderData.status) ? null : ["de", "ua", "lt", "lv", "ee"].includes(orderData.country) ? null : {
                            icon: "perm_data_setting",
                            collapse: true,
                            label: "Baza pośrednia",
                            options: [
                                {
                                    label: orderData.id.toString().charAt(0) === "2" ? "Przenieś do K99" : "Przenieś do X99",
                                    onClick: () => this.handleOrderTransfer(orderData)
                                },
                                {
                                    label: "Przenieś do salonu N32 w X99",
                                    onClick: () => this.handleOrderTransferN32(orderData)
                                },
                                {
                                    label: "Komunikat status U",
                                    onClick: () => this.handleWebFrontierRequest(orderData, "U")
                                },
                                {
                                    label: "Komunikat status D",
                                    onClick: () => this.handleWebFrontierRequest(orderData, "D")
                                },
                                {
                                    label: "Zmień na salonowe",
                                    onClick: () => this.handleChangeSlug(orderData, "parlour")
                                },
                                {
                                    label: "Zmień na wewnętrzne",
                                    onClick: () => this.handleChangeSlug(orderData, "e99_157_parlour")
                                },
                                {
                                    label: "Zmień na e99_157",
                                    onClick: () => this.handleChangeSlug(orderData, "e99_157")
                                }
                            ]
                        },
                        {
                            icon: "perm_data_setting",
                            label: "Kopiuj zamówienie",
                            onClick: () => this.handleCopyOrder(orderData),
                            collapse: true
                        },
                        {
                            icon: "perm_data_setting",
                            label: "Zmień status zamówienia",
                            onClick: () => {
                                this.setState({
                                    changeStatus: true
                                })
                            },
                            collapse: true
                        },
                        {
                            icon: "perm_data_setting",
                            label: "Wyślij powiadomienie",
                            onClick: () => {
                                this.setState({
                                    changeStatus: true
                                })
                            },
                            collapse: true,
                            options: mailerVariants.map(e => ({
                                label: e.label,
                                onClick: () => {
                                    Confirm("Czy na pewno wysłać powiadomienie e-mail?", null, () => {
                                        api().post("/api/mailer/sendExternalMail",
                                            {
                                                orderId: orderData.id,
                                                variant: e.key,
                                                userId:  DATA_OBJECT.user_login
                                            })
                                            .then(result => {
                                                if (result.data.success) {
                                                    SnackBar("Wysłano powiadomienie e-mail");
                                                } else {
                                                    SnackBar("Błąd podczas wysyłania powiadomienia. Sprawdź konfigurację.", "error");
                                                }
                                            })
                                    })
                                }
                            }))
                        },
                        {
                            icon: "perm_data_setting",
                            label: "Anuluj zamówienie",
                            collapse: true,
                            options: [{
                                label: "Anuluj i wyślij email",
                                onClick: () => this.handleOrderCancel(orderData)
                            }, {
                                label: "Anuluj bez email",
                                onClick: () => this.handleOrderCancel(orderData, true)
                            }]
                        },
                        {
                            icon: "perm_data_setting",
                            label: "Resetuj kafelki",
                            onClick: () => {
                                localStorage.removeItem("order_grid");

                                window.location.reload();
                            },
                            collapse: true
                        },
                        {
                            icon: "perm_data_setting",
                            label: "Sync AppChance",
                            onClick: () => {
                                Confirm("Czy na pewno chcesz zsynchronizować zamóienie?", null, () => {
                                    api().post("/api/orders/syncAppchanceOrder",
                                        {
                                            orderId: orderData.id,
                                        })
                                        .then(result => {
                                            SnackBar("Zsynchronizowano zamówienie w AppChance");
                                        })
                                })
                            },
                            collapse: true
                        }
                    ]}
                />

                <div style={{marginLeft: 10, marginRight: 10}}>
                    {!props.orderData &&
                    <Breadcrumb
                        crumbs={[
                            {
                                label: `Zamówienia`,
                                href: "/orders"
                            },
                            {
                                label: `Edycja`,
                                href: "/orders"
                            },
                            {
                                label: `${orderData.id}`,
                                href: "/orders"
                            },
                        ]}
                    />
                    }

                    {orderData ?
                        <React.Fragment>
                            <Tabs className={classes.root + " es-panel-tab-container"} value={tab} onChange={(e, v) => this.handleChangeTab(e, v)}>
                                <Tab label={TranslationController.translate("Dane zamówienia")} />
                                <Tab label={TranslationController.translate("Koszyk")} />
                                <Tab label={TranslationController.translate("Historia i komunikacja")} />
                                <Tab disabled={!(orderData.itemConfigs && orderData.itemConfigs.length)} label={TranslationController.translate("Personalizowane produkty")} />
                                <Tab label={TranslationController.translate("Historia rezerwacji")} />
                            </Tabs>

                            {tab === 0 && this.state.shippingMethods && this.state.shippingMethodsList &&
                            <TabContainer>
                                {/*{orderData.needToRePromo &&*/}
                                {/*    <div style={{*/}
                                {/*        marginTop: 10,*/}
                                {/*        marginBottom: 20*/}
                                {/*    }}>*/}
                                {/*        <Message*/}
                                {/*            header={"Zmieniono koszyk objęty promocją!"}*/}
                                {/*            content={"Zastosuj promocję docelową aby ponownie przeliczyć koszyk"}*/}
                                {/*            error*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*}*/}

                                <OrderGrid
                                    orderData={orderData}
                                    editState={editState}
                                    isParlourOrder={this.state.isParlourOrder}
                                    internalOrder={orderData.internalOrder}
                                    shippingMethods={this.state.shippingMethods}
                                    setState={(state) => this.setState(state)}
                                    shippingMethodsList={this.state.shippingMethodsList}
                                    state={this.state}
                                    controller={this}

                                />
                            </TabContainer>
                            }


                            {tab === 1 &&
                            <TabContainer>
                                <OrderCart
                                    deliveryManagementProps={{
                                        orderData: orderData,
                                        editState: editState,
                                        isParlourOrder: this.state.isParlourOrder,
                                        internalOrder: orderData.internalOrder,
                                        shippingMethods: this.state.shippingMethods,
                                        setState: (state) => this.setState(state),
                                        shippingMethodsList: this.state.shippingMethodsList,
                                        state: this.state,
                                        controller: this,
                                    }}
                                    shippingMethods={this.state.shippingMethods}
                                    order={orderData}
                                    data={orderData.items}
                                    orderId={orderData.id}
                                    internalOrder={orderData.internalOrder}
                                    isParlourOrder={this.state.isParlourOrder}
                                    refresh={() => this.hardRefresh()}
                                    reCompltation={(data) => this.reCompltation(data)}
                                    setDimmer={() => {
                                        this.setState({
                                            dimmer: true
                                        })
                                    }}
                                    closeOrder={() => {
                                        orderData.status = "completed";
                                        orderData.statusSynchro = "solved";
                                        orderData.statusCompletation = "send";

                                        if (orderData.shippingMethodId === "60630e23fb914811cf65b65b") {
                                            orderData.statusCompletation = "sendToParlour";
                                        } else {
                                            orderData.statusCompletation = "sendToCustomer";
                                        }

                                        this.handleOrderSave(orderData);
                                    }}
                                />
                            </TabContainer>
                            }

                            {this.state.labelHistoryModal &&
                                <Modal dimmer={"inverted"} size={"mini"} open={this.state.paymentRevertModal} onClose={() => this.setState({paymentRevertModal: false})}>
                                    <Modal.Header>{TranslationController.translate("Zwrot płatności")}</Modal.Header>
                                    <Modal.Content>

                                        <div style={{
                                            marginBottom: 25
                                        }}>
                                            Kwota zwrotu: <span style={{fontWeight: 600}}>{parseFloat(this.state.paymentRevertAmount).toFixed(2)}</span> {orderData.currency}
                                        </div>

                                        <EsotiqInput
                                            type={"number"}
                                            label={"Inna kwota zwrotu"}
                                            onChange={(value) => {
                                                if (value < 1) {
                                                    this.state.paymentRevertAmount = 1;
                                                } else {
                                                    this.state.paymentRevertAmount = value;
                                                }

                                                this.forceUpdate();
                                            }}
                                        />

                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button onClick={() => this.setState({paymentRevertModal: false})} negative>Anuluj</Button>
                                        <Button
                                            positive
                                            icon='checkmark'
                                            labelPosition='right'
                                            content='Potwierdź'
                                            onClick={() => {
                                                Confirm("Czy na pewno chcesz dokonać zwrotu transakcji?", "", () => {
                                                    api().post("/api/orders/payments/bluemedia/revert", {user: DATA_OBJECT.user_login, provider: "bluemedia", paymentId: orderData.paymentData.payment_id, transactionId: orderData.paymentData.external_id, orderId: orderData.id, amount: Math.abs(parseFloat(this.state.paymentRevertAmount).toFixed(2))})
                                                        .then(r => {
                                                            if (r.data.error) {
                                                                return SnackBar(r.data.message, "error")
                                                            }

                                                            this.hardRefresh();

                                                            SnackBar("Poprawnie dokonano zwrotu");

                                                            this.setState({
                                                                paymentRevertModal: false
                                                            })
                                                        })
                                                })
                                            }}
                                        />
                                    </Modal.Actions>
                                </Modal>
                            }

                            {this.state.packNumberChange &&
                                <Modal dimmer={"inverted"} size={"mini"} open={this.state.packNumberChange} onClose={() => this.setState({packNumberChange: null})}>
                                    <Modal.Header>{TranslationController.translate("Zmień numer paczki")}</Modal.Header>
                                    <Modal.Content>

                                        <EsotiqInput
                                            label={"Nowy numer paczki"}
                                            onChange={(value) => {
                                                this.state.packNumberChange.newNumber = value;

                                                this.forceUpdate();
                                            }}
                                        />

                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button onClick={() => this.setState({packNumberChange: null})} negative>Anuluj</Button>
                                        <Button
                                            positive
                                            icon='checkmark'
                                            labelPosition='right'
                                            content='Potwierdź'
                                            onClick={() => {
                                                Confirm("Czy na pewno chcesz dokonać zwrotu transakcji?", "", () => {
                                                    api().post("/api/orders/delivery/change", this.state.packNumberChange)
                                                        .then(r => {
                                                            if (r.data.error) {
                                                                return SnackBar(r.data.message, "error")
                                                            }

                                                            this.hardRefresh();

                                                            SnackBar("Zmieniono numer paczki");

                                                            this.setState({
                                                                packNumberChange: null
                                                            })
                                                        })
                                                })
                                            }}
                                        />
                                    </Modal.Actions>
                                </Modal>
                            }

                            {this.state.paymentRevertModal &&
                            <Modal dimmer={"inverted"} size={"mini"} open={this.state.paymentRevertModal} onClose={() => this.setState({paymentRevertModal: false})}>
                                <Modal.Header>{TranslationController.translate("Zwrot płatności")}</Modal.Header>
                                <Modal.Content>

                                    <div style={{
                                        marginBottom: 25
                                    }}>
                                        Kwota zwrotu: <span style={{fontWeight: 600}}>{parseFloat(this.state.paymentRevertAmount).toFixed(2)}</span> {orderData.currency}
                                    </div>

                                    <EsotiqInput
                                        type={"number"}
                                        label={"Inna kwota zwrotu"}
                                        onChange={(value) => {
                                            if (value < 1) {
                                                this.state.paymentRevertAmount = 1;
                                            } else {
                                                this.state.paymentRevertAmount = value;
                                            }

                                            this.forceUpdate();
                                        }}
                                    />

                                </Modal.Content>
                                <Modal.Actions>
                                    <Button onClick={() => this.setState({paymentRevertModal: false})} negative>Anuluj</Button>
                                    <Button
                                        positive
                                        icon='checkmark'
                                        labelPosition='right'
                                        content='Potwierdź'
                                        onClick={() => {
                                            Confirm("Czy na pewno chcesz dokonać zwrotu transakcji?", "", () => {
                                                api().post("/api/orders/payments/bluemedia/revert", {
                                                    user: DATA_OBJECT.user_login,
                                                    provider: "bluemedia",
                                                    paymentId: orderData.paymentData.payment_id,
                                                    transactionId: orderData.paymentData.external_id,
                                                    orderId: orderData.id,
                                                    amount: Math.abs(parseFloat(this.state.paymentRevertAmount).toFixed(2))
                                                })
                                                    .then(r => {
                                                        if (r.data.error) {
                                                            return SnackBar(r.data.message, "error")
                                                        }

                                                        this.hardRefresh();

                                                        SnackBar("Poprawnie dokonano zwrotu");

                                                        this.setState({
                                                            paymentRevertModal: false
                                                        })
                                                    })
                                            })
                                        }}
                                    />
                                </Modal.Actions>
                            </Modal>
                            }

                            {this.state.addPaymentModal &&
                                <Modal dimmer={"inverted"} size={"mini"} open={this.state.addPaymentModal} onClose={() => this.setState({addPaymentModal: false})}>
                                    <Modal.Header>{TranslationController.translate("Dodaj nową płatność")}</Modal.Header>
                                    <Modal.Content>

                                        <div style={{
                                            marginBottom: 25
                                        }}>
                                            Kwota wpłaty: <span style={{fontWeight: 600}}>{parseFloat(this.state.addPaymentAmount).toFixed(2)}</span> {orderData.currency}
                                        </div>

                                        <EsotiqInput
                                            type={"number"}
                                            label={"Kwota wpłaty"}
                                            onChange={(value) => {
                                                if (value < 1) {
                                                    this.state.addPaymentAmount = 1;
                                                } else {
                                                    this.state.addPaymentAmount = value;
                                                }

                                                this.forceUpdate();
                                            }}
                                        />

                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button onClick={() => this.setState({addPaymentModal: false})} negative>Anuluj</Button>
                                        <Button
                                            positive
                                            icon='checkmark'
                                            labelPosition='right'
                                            content='Potwierdź'
                                            onClick={() => {
                                                Confirm("Czy na pewno chcesz dodać nową płatność?", "", () => {
                                                    api().post("/api/orders/addPayment", {user: DATA_OBJECT.user_login, orderId: orderData.id, amount: Math.abs(parseFloat(this.state.addPaymentAmount))})
                                                        .then(r => {
                                                            if (r.data.error) {
                                                                return SnackBar(r.data.message, "error")
                                                            }

                                                            this.hardRefresh();

                                                            SnackBar("Poprawnie dodano płatność");

                                                            this.setState({
                                                                addPaymentModal: false
                                                            })
                                                        })
                                                })
                                            }}
                                        />
                                    </Modal.Actions>
                                </Modal>
                            }

                            {this.state.changeStatus &&
                            <Modal dimmer={"inverted"} size={"mini"} open={this.state.changeStatus} onClose={() => {
                                orderData.status = this.state.initOrderData.status;
                                orderData.statusCompletation = this.state.initOrderData.statusCompletation;
                                orderData.gateStatus = this.state.initOrderData.gateStatus;
                                orderData.statusShipping = this.state.initOrderData.statusShipping;
                                orderData.statusSynchro = this.state.initOrderData.statusSynchro;

                                this.setState({changeStatus: false});
                            }}>
                                <Modal.Header>{TranslationController.translate("Zmień status zamówienia")}</Modal.Header>
                                <Modal.Content>

                                    <EsotiqInput
                                        label={"Status"}
                                        options={new OrderStatusesHelper().baseStatuses.map(e => (
                                            {
                                                key: e.id, value: e.id, text: TranslationController.translate(e.label)
                                            }
                                        ))}
                                        onChange={(value) => {
                                            orderData.status = value;
                                        }}
                                    />

                                    {!["ukr", "prib"].includes(orderData.initSlug) &&
                                        <React.Fragment>
                                            <EsotiqInput
                                                label={"Kompletacja"}
                                                options={new OrderStatusesHelper().completationStatuses.map(e => (
                                                    {
                                                        key: e.id, value: e.id, text: TranslationController.translate(e.label)
                                                    }
                                                ))}
                                                onChange={(value) => {
                                                    orderData.statusCompletation = value;
                                                }}
                                            />

                                            <EsotiqInput
                                                label={"GATE"}
                                                options={new OrderStatusesHelper().gateStatuses.map(e => (
                                                    {
                                                        key: e.id, value: e.id, text: TranslationController.translate(e.label)
                                                    }
                                                ))}
                                                onChange={(value) => {
                                                    if (value === "null") value = null;

                                                    orderData.gateStatus = value;
                                                }}
                                            />
                                        </React.Fragment>
                                    }

                                    <EsotiqInput
                                        label={"Wysyłka"}
                                        options={(orderData.initSlug === "prib" ? new OrderStatusesHelper().shippingStatusesPrib
                                        : orderData.initSlug === "ukr" ? new OrderStatusesHelper().shippingStatusesUkr : new OrderStatusesHelper().shippingStatuses).map(e => (
                                            {
                                                key: e.id, value: e.id, text: TranslationController.translate(e.label)
                                            }
                                        ))}
                                        onChange={(value) => {
                                            orderData.statusShipping = value;
                                        }}
                                    />

                                    {!["ukr", "prib"].includes(orderData.initSlug) &&
                                    <React.Fragment>
                                        <EsotiqInput
                                            label={"Sync"}
                                            options={new OrderStatusesHelper().synchroStatuses.map(e => (
                                                {
                                                    key: e.id, value: e.id, text: TranslationController.translate(e.label)
                                                }
                                            ))}
                                            onChange={(value) => {
                                                orderData.statusSynchro = value;
                                            }}
                                        />
                                    </React.Fragment>
                                    }

                                </Modal.Content>
                                <Modal.Actions>
                                    <Button onClick={() => {
                                        orderData.status = this.state.initOrderData.status;
                                        orderData.statusCompletation = this.state.initOrderData.statusCompletation;
                                        orderData.gateStatus = this.state.initOrderData.gateStatus;
                                        orderData.statusShipping = this.state.initOrderData.statusShipping;
                                        orderData.statusSynchro = this.state.initOrderData.statusSynchro;

                                        this.setState({changeStatus: false});
                                    }} negative>Anuluj</Button>
                                    <Button
                                        positive
                                        icon='checkmark'
                                        labelPosition='right'
                                        content='Zapisz'
                                        onClick={() => {
                                            if (orderData.statusCompletation === new OrderStatusesHelper().statuses.assigned.id) {
                                                orderData.statusSynchro = new OrderStatusesHelper().statuses.solved.id;
                                            }

                                            this.handleOrderSave(orderData);
                                            this.setState({changeStatus: false});
                                        }}
                                    />
                                </Modal.Actions>
                            </Modal>
                            }

                            {tab === 2 &&
                                <OrderHistory
                                    orderId={orderData.id}
                                    orderData={orderData}
                                />
                            }

                            {tab === 3 &&
                                <PersonalizedItems
                                    orderData={orderData}
                                />
                            }

                            {orderData.orderPicker && orderData.orderPicker === 'V2' && tab === 4 &&
                                <ReservationHistory
                                    orderData={orderData}
                                />
                            }

                            {this.state.dimmer &&
                                <Dimmer active inverted style={{
                                    position: "fixed",
                                    zIndex: 99999
                                }}>
                                    <Loader size='medium'>{TranslationController.translate("Proszę czekać...")}</Loader>
                                </Dimmer>
                            }
                        </React.Fragment> : <LinearProgress />
                    }             </div>

            </div> : <div style={{
                position: "relative",
                top: 250
            }}><Loader active></Loader></div>
        )
    }
}

export default OrderPreview
