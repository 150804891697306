import React from "react";
import Button from "../Button";
import {DATA_OBJECT} from "../../controller/dataController";
import {api, STATIC_URL} from "../../lib/axios";
import SnackBar from "../Snack";
import GlobalDimmer from "../GlobalDimmer";

export default class DownloadPickupFileButton extends React.Component {
    render() {
        const {row} = this.props;

        return (
            <React.Fragment>
                <Button
                    color='blue'
                    content='List przewozowy'
                    icon='download'
                    label={{ basic: true, color: 'blue', pointing: 'left', content: '.pdf' }}
                    onClick={() => {
                        GlobalDimmer.Set();

                        switch (row.provider) {
                            case "gls":
                                const format = ["K99", "E99"].includes(DATA_OBJECT.selected_parlour) ? 'smallPdf' : 'a4';
                                api().get(`/api/orders/delivery/getLabel/${row.pickupNumber}/gls?format=${format}&parlour=${DATA_OBJECT.selected_parlour}&protocol=${row.protocol ?? ''}`).then(
                                    (result) => {
                                        if(result.data.error) {
                                            GlobalDimmer.Unset();
                                            return SnackBar(result.data.error, "error")
                                        }
                                        const arrayBuffer = Uint8Array.from(result.data.label.data).buffer;
                                        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                        const blobUrl = URL.createObjectURL(blob);
                                        window.open(blobUrl, "_blank")

                                        GlobalDimmer.Unset()
                                    }
                                )
                                break;
                            case "dpd":
                                window.open(`${STATIC_URL}${row.pickupFile}`);

                                GlobalDimmer.Unset()
                                break;
                            case "inpost":
                                api().post("/api/orders/delivery/getLabel", {
                                    orderId: row.orderId,
                                    provider: "inpost",
                                    forceId: row.pickupNumber
                                })
                                    .then(result => {
                                        if (result.data.error) {
                                            GlobalDimmer.Unset();
                                            return SnackBar(JSON.stringify(result.data.message, undefined, true), "error")
                                        }

                                        fetch(result.data.urlParams.url, {
                                            method: result.data.urlParams.method,
                                            headers: result.data.urlParams.headers
                                        })
                                            .then(res => {
                                                return res.blob()
                                            })
                                            .then(blob => {
                                                const file = window.URL.createObjectURL(blob);
                                                window.open(file, "_blank");

                                                GlobalDimmer.Unset();
                                            })
                                    })
                                break;
                            case "inpost_courier":
                                api().post("/api/orders/delivery/getLabel", {
                                    orderId: row.orderId,
                                    provider: "inpost",
                                    forceId: row.pickupNumber
                                })
                                    .then(result => {
                                        if (result.data.error) {
                                            GlobalDimmer.Unset();
                                            return SnackBar(JSON.stringify(result.data.message, undefined, true), "error")
                                        }

                                        fetch(result.data.urlParams.url, {
                                            method: result.data.urlParams.method,
                                            headers: result.data.urlParams.headers
                                        })
                                            .then(res => {
                                                return res.blob()
                                            })
                                            .then(blob => {
                                                const file = window.URL.createObjectURL(blob);
                                                window.open(file, "_blank");

                                                GlobalDimmer.Unset();
                                            })
                                    })
                                break;
                            default:
                                window.open(row.pickupFile);
                                GlobalDimmer.Unset();
                                break;
                        }
                    }}
                />
            </React.Fragment>
        )
    }
}