import {api} from "../lib/axios";
import {DATA_OBJECT} from "../controller/dataController";
import SnackBar from "../components/Snack";
import GlobalDimmer from "../components/GlobalDimmer";

export function generatePickup(row) {
    return new Promise(resolve => {
        GlobalDimmer.Set();

        api().post("/api/orderService/generatePickup", {
            orderId: row.orderId,
            parlour: row.parlour,
            userId: DATA_OBJECT.user.login,
            id: row._id,
            multiCreate: row.multiCreate,
            rowIds: row.rowIds,
            complaints: row.complaints
        }).then((r) => {
            if (r?.data?.validation?.ValidationInfo?.length) {
                r.data.validation.ValidationInfo.forEach((e) => {
                    SnackBar(e.Info, "error");
                })

                GlobalDimmer.Unset();
                resolve(row);
                return;
            }

            if (r.data.error) {
                resolve(null);
                GlobalDimmer.Unset();
                return SnackBar(r.data.message, "error");
            }

            row.pickupNumber = r.data.data
            row.pickupFile = r.data.file
            row.pickupCreateDate = r.data.pickupCreateDate
            row.pickupSessionPackageId = r.data.pickupSessionPackageId

            GlobalDimmer.Unset();
            resolve(row);
        })
    })
}