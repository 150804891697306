import React from 'react'
import Breadcrumb from "../../../components/Breadcrumb";
import UITable from "../../../lib/table-v2";
import Container from "../../../components/Container";
import {api} from "../../../lib/axios";
import ActionPanel from "../../../components/ActionPanel";
import Button from "../../../components/Button";
import {Modal, Input, Label, Menu, Segment, Icon, Dimmer, Loader, Table} from 'semantic-ui-react'
import SnackBar from "../../../components/Snack";
import DataPresentation from "../../../components/DataPresentation";
import {TranslationController} from "../../../controller/menager";
import {exportToFile, saveToFile} from "../../../controller/exportController";
import CsvImport from "../../../components/CsvImport";
import {DATA_OBJECT} from "../../../controller/dataController";
import TableMenu from "../../../components/TableMenu";
import MenuButton from "../../../lib/table-v2/menuButton";

class States extends React.Component {
    state = {
        loading: false,
        apiUrl: "/api/states",
        modalOpened: null,
        warehouse: "global",
        tableStockUnitSource: "smt",
        warehousesOptions: [
            {key: "157", text: "157", value: "157", selected: true},
            {key: "salon", text: "salon", value: "salon"},
            {key: "k99", text: "k99", value: "k99"},
            {key: "e99", text: "e99", value: "e99"},
            {key: "out", text: "out", value: "out"},
            {key: "de", text: "de", value: "de"},
            {key: "vos", text: "vos", value: "vos"},
        ]
    }

    componentDidMount() {
        this.getConfig();
    }

    init(limit, currentPage, warehouse) {
        this.setState({
            noLimitCall: api().post(this.state.apiUrl)
                .then(r => {
                    return r.data.data
                })
        })

        return api().post(this.state.apiUrl, {limit, currentPage, filter: this.state.appliedFilters || null})
            .then(r => {
                this.setState({data: r.data.data, loading: false})

                return r.data.data
            })
    }

    setCorrection(row, warehouse, correction) {
        let updatedWarehouse = row
        updatedWarehouse[warehouse].correction = correction

        this.setState({
            modalOpened: false
        })

        return api().post("/api/states/update", {data: {
                sku: row.sku,
                correction,
                warehouse
            }})
            .then(r => {

            })
    }

    setWarehouse(warehouse) {
        this.setState({
            warehouse: null
        })

        setTimeout(() => {
            this.setState({
                warehouse
            })
        }, 100)
    }

    getConfig() {
        return api().get("/api/states/config")
            .then(r => {
                if (!r.data.data) {
                    this.setState({configs: [
                            {
                                bufforVGL: 0,
                                bufforSalVGLon: 0,
                                bufforSalVGLoff: 0,
                                shippingtime: {}
                            }
                        ]})

                } else {
                    this.setState({configs: [r.data.data]});

                    return r.data.data
                }
            })
    }

    setConfig() {
        return api().post("/api/states/config/set", {config: this.state.configs})
            .then(r => {
                this.setState({editConfig: false})

                return SnackBar("Zapisano pomyślnie.")
            })
    }

    handleApiUrlChange(source) {
        this.setState({
            apiUrl: "/api/states/" + (source === "order-picker" ? "order-picker" : ""),
        }, () => this.refreshTable(source));
    }

    refreshTable(source) {
        this.setState({ data: null, loading: true }, () => {
            const currentWarehouse = this.state.warehouse;

            this.setState({ warehouse: null }, () => {
                this.init()
                    .then(() => {
                        this.setState({ warehouse: currentWarehouse, loading: false, tableStockUnitSource: source });
                    });
            });
        })
    }

    handleRowClick(row, warehouse) {
        if (this.state.modalOpened) {
            return false
        }

        this.setState({
            dimmer: true
        })

        api().post(`${this.state.apiUrl}/getByIndex`, {index: [{sku: row.sku}]})
            .then(r => {
                if (r.data) {
                    if (r.data.data[0] && r.data.data[0].state) {
                        this.setState({
                            dimmer: false,
                            warehouseState: warehouse === "salon"
                                ? r.data.data[0].state.filter(e => !["D", "K99", "157", "N32", "OUT"].includes(e.parlour))
                                :  r.data.data[0].state.filter(e => e.parlour === warehouse)
                        })
                    }
                }
            })

    }

    render() {
        return (
            <React.Fragment>
                <ActionPanel
                    actions={[]}
                />

                <Container>
                    <Breadcrumb
                        crumbs={[
                            {
                                label: `Stany magazynowe ${this.state.dataCount ? `(${this.state.dataCount})` : ""}`
                            }
                        ]}
                    />

                    <div style={{height: 10}}></div>

                    <Menu className={"order-menu"} attached='top'>
                        <TableMenu item icon='wrench' simple>
                            <TableMenu.Menu>

                                <TableMenu.Divider />
                                <TableMenu.Header>Warunki magazynowe</TableMenu.Header>

                                <TableMenu.Item onClick={() => this.setState({
                                    editConfig: true
                                })}>Edytuj warunki</TableMenu.Item>

                                <TableMenu.Divider />
                                <TableMenu.Header>Import</TableMenu.Header>
                                <TableMenu.Item>
                                    <CsvImport
                                        csvStructure={[
                                            {column: "warehouse"},
                                            {column: "sku"},
                                            {column: "correction"},
                                        ]}
                                        onUpdate={(data) => {
                                            this.setState({
                                                dimmer: true
                                            })

                                            return api().post("/api/states/import-correction", {data})
                                                .then((data) => {
                                                    this.orderTable.setState({
                                                        data: null
                                                    });

                                                    this.orderTable.init();

                                                    this.setState({
                                                        dimmer: false
                                                    })

                                                    return data;
                                                })
                                        }}
                                    />
                                </TableMenu.Item>

                                <TableMenu.Divider />
                                <TableMenu.Header>Eksport</TableMenu.Header>

                                <TableMenu.Item>
                                    <Button
                                        color='blue'
                                        content='Pobierz korekty'
                                        icon='download'
                                        label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                        onClick={() => {
                                            this.setState({
                                                dimmer: true
                                            });

                                            return api().post(this.state.apiUrl, {
                                                isExport: {
                                                    columns: ["warehouse", "sku", "correction", "state"],
                                                    fields: [
                                                        {
                                                            field: "warehouse",
                                                        },
                                                        {
                                                            field: "sku",
                                                        },
                                                        {
                                                            field: "correction",
                                                        },
                                                        {
                                                            field: "state",
                                                        }
                                                    ]
                                                },
                                                limit: 99000,
                                                currentPage: 1,
                                                filter: this.orderTable.state.appliedFilters,
                                                internalOrders: this.state.internalOrders
                                            })
                                                .then(r => {
                                                    this.setState({
                                                        dimmer: false
                                                    });

                                                    if (r.data.error) {
                                                        return SnackBar(r.data.message, "error")
                                                    }

                                                    return saveToFile(r.data);
                                                });
                                        }}
                                    />
                                </TableMenu.Item>
                            </TableMenu.Menu>
                        </TableMenu>
                        <Menu.Menu position='left'>
                            <MenuButton
                                icon={"list"}
                                onClick={() => {
                                    this.handleApiUrlChange("smt")
                                }}
                                label={"Stany SMT"}
                            />

                            <MenuButton
                                onClick={() =>
                                    this.handleApiUrlChange("order-picker")
                                }
                                icon={"list"}
                                label={"Stany OP"}
                            />
                        </Menu.Menu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        {this.state.warehouse &&
                            <UITable
                                dataFetch={{
                                    url: this.state.apiUrl,
                                    options: {limit: 25, currentPage: 1, warehouse: this.state.warehouse}
                                }}
                                ref={table => this.orderTable = table}
                                provider={this}
                                columns={[
                                    {
                                        label: "SKU",
                                        field: "sku",
                                        filters: [
                                            {label: "SKU", condition: "in", type: "text"}
                                        ]
                                    },
                                    DATA_OBJECT["user"]["login"] === "root" ? {
                                        label: "Stan",
                                        width: 1,
                                        field: "allState",
                                        render: row =>
                                            <div
                                                style={{
                                                    position: "relative",
                                                }}
                                            >
                                                <div>
                                                    {DATA_OBJECT["user"]["login"] !== "root" && row.allState < 0 ? 0 : row.allState}
                                                </div>
                                            </div>,
                                    } : null,
                                    {
                                        label: "Sklep",
                                        field: this.state.tableStockUnitSource === "order-picker" ? "e99" : "k99",
                                        render: row => {
                                            const warehouse = this.state.tableStockUnitSource === "order-picker" ? "e99" : "k99";
                                            return (
                                                <div
                                                    style={{position: "relative"}}
                                                >
                                                    <div onClick={() => this.setState({modalOpened: {row, warehouse: warehouse}})} className={"states-correction-table-display"}>{row[warehouse].correction}</div>

                                                    {DATA_OBJECT["user"]["login"] !== "root" && row[warehouse].state < 0 ? 0 : row[warehouse].state}

                                                    {row[warehouse].blocked ?
                                                        <div style={{
                                                            marginTop: 3,
                                                            display: "flex"
                                                        }}>
                                                            <div style={{
                                                                fontSize: "0.9em",
                                                                color: "#006b86",
                                                                marginRight: 1
                                                            }}>
                                                                {TranslationController.translate("Zablk.")} (System):
                                                            </div>

                                                            <div style={{
                                                                fontSize: "0.8em",
                                                                color: "#575757",
                                                                fontWeight: 600
                                                            }}>
                                                                {row[warehouse].blocked}
                                                            </div>
                                                        </div>
                                                        : <React.Fragment/>
                                                    }

                                                    {row[warehouse].frontendBlocked ?
                                                        <div style={{
                                                            marginTop: 3,
                                                            display: "flex"
                                                        }}>
                                                            <div style={{
                                                                fontSize: "0.9em",
                                                                color: "#006b86",
                                                                marginRight: 1
                                                            }}>
                                                                {TranslationController.translate("Zablk.")} (Frontend):
                                                            </div>

                                                            <div style={{
                                                                fontSize: "0.8em",
                                                                color: "#575757",
                                                                fontWeight: 600
                                                            }}>
                                                                {row[warehouse].frontendBlocked}
                                                            </div>
                                                        </div>
                                                        : <React.Fragment/>
                                                    }
                                                </div>
                                            )
                                        },
                                        onClick: row => this.handleRowClick(row, this.state.tableStockUnitSource === "order-picker" ? "e99" : "k99")
                                    },
                                    {
                                        label: "Główny",
                                        field: "157",
                                        render: row => <div style={{position: "relative"}}>
                                            <div onClick={() => this.setState({modalOpened: {row, warehouse: "157"}})} className={"states-correction-table-display"}>{row["157"].correction}</div>
                                            {DATA_OBJECT["user"]["login"] !== "root" && row["157"].state < 0 ? 0 : row["157"].state}

                                            {row["157"].blocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (System):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["157"].blocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }

                                            {row["157"].frontendBlocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (Frontend):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["157"].frontendBlocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }
                                        </div>,
                                        onClick: row => this.handleRowClick(row, "157")
                                    },
                                    {
                                        label: "VOS",
                                        field: "vos",
                                        render: row => <div style={{position: "relative"}}>
                                            <div onClick={() => this.setState({modalOpened: {row, warehouse: "vos"}})} className={"states-correction-table-display"}>{row["vos"].correction}</div>
                                            {DATA_OBJECT["user"]["login"] !== "root" && row["vos"].state < 0 ? 0 : row["vos"].state}

                                            {row["n32"].blocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (System):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["vos"].blocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }

                                            {row["vos"].frontendBlocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (Frontend):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["vos"].frontendBlocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }
                                        </div>,
                                        onClick: row => this.handleRowClick(row, "vos")
                                    },
                                    {
                                        label: "Niemcy",
                                        field: "de",
                                        render: row => <div style={{position: "relative"}}>
                                            <div onClick={() => this.setState({modalOpened: {row, warehouse: "de"}})} className={"states-correction-table-display"}>{row["de"].correction}</div>
                                            {DATA_OBJECT["user"]["login"] !== "root" && row["de"].state < 0 ? 0 : row["de"].state}

                                            {row["de"].blocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (System):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["de"].blocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }

                                            {row["de"].frontendBlocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (Frontend):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["de"].frontendBlocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }
                                        </div>,
                                        onClick: row => this.handleRowClick(row, "de")
                                    },
                                    {
                                        label: "Salony",
                                        field: "salon",
                                        render: row => <div style={{position: "relative"}}>
                                            <div onClick={() => this.setState({modalOpened: {row, warehouse: "salon"}})} className={"states-correction-table-display"}>{row["salon"].correction}</div>
                                            {DATA_OBJECT["user"]["login"] !== "root" && row["salon"].state < 0 ? 0 : row["salon"].state}

                                            {row["salon"].blocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (System):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["salon"].blocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }

                                            {row["salon"].frontendBlocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (Frontend):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["salon"].frontendBlocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }
                                        </div>,
                                        onClick: row => this.handleRowClick(row, "salon")
                                    },
                                    {
                                        label: this.state.tableStockUnitSource === "order-picker" ? "S99" : "X99",
                                        field: this.state.tableStockUnitSource === "order-picker" ? "s99" : "x99",
                                        render: row => {
                                            const warehouse = this.state.tableStockUnitSource === "order-picker" ? "s99" : "x99";
                                            return (
                                                <div
                                                    style={{position: "relative"}}
                                                >
                                                    <div onClick={() => this.setState({modalOpened: {row, warehouse: warehouse}})} className={"states-correction-table-display"}>{row[warehouse].correction}</div>

                                                    {DATA_OBJECT["user"]["login"] !== "root" && row[warehouse].state < 0 ? 0 : row[warehouse].state}

                                                    {row[warehouse].blocked ?
                                                        <div style={{
                                                            marginTop: 3,
                                                            display: "flex"
                                                        }}>
                                                            <div style={{
                                                                fontSize: "0.9em",
                                                                color: "#006b86",
                                                                marginRight: 1
                                                            }}>
                                                                {TranslationController.translate("Zablk.")} (System):
                                                            </div>

                                                            <div style={{
                                                                fontSize: "0.8em",
                                                                color: "#575757",
                                                                fontWeight: 600
                                                            }}>
                                                                {row[warehouse].blocked}
                                                            </div>
                                                        </div>
                                                        : <React.Fragment/>
                                                    }

                                                    {row[warehouse].frontendBlocked ?
                                                        <div style={{
                                                            marginTop: 3,
                                                            display: "flex"
                                                        }}>
                                                            <div style={{
                                                                fontSize: "0.9em",
                                                                color: "#006b86",
                                                                marginRight: 1
                                                            }}>
                                                                {TranslationController.translate("Zablk.")} (Frontend):
                                                            </div>

                                                            <div style={{
                                                                fontSize: "0.8em",
                                                                color: "#575757",
                                                                fontWeight: 600
                                                            }}>
                                                                {row[warehouse].frontendBlocked}
                                                            </div>
                                                        </div>
                                                        : <React.Fragment/>
                                                    }
                                                </div>
                                            )
                                        },
                                        onClick: row => this.handleRowClick(row, this.state.tableStockUnitSource === "order-picker" ? "s99" : "x99")
                                    },
                                    {
                                        label: "PRIB",
                                        field: "prib",
                                        render: row => <div style={{position: "relative"}}>
                                            <div onClick={() => this.setState({modalOpened: {row, warehouse: "prib"}})} className={"states-correction-table-display"}>{row["prib"].correction}</div>
                                            {DATA_OBJECT["user"]["login"] !== "root" && row["prib"].state < 0 ? 0 : row["prib"].state}

                                            {row["prib"].blocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (System):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["prib"].blocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }

                                            {row["prib"].frontendBlocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (Frontend):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["prib"].frontendBlocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }
                                        </div>,
                                        onClick: row => this.handleRowClick(row, "prib")
                                    },
                                    {
                                        label: "UKR",
                                        field: "ukr",
                                        render: row => <div style={{position: "relative"}}>
                                            <div onClick={() => this.setState({modalOpened: {row, warehouse: "ukr"}})} className={"states-correction-table-display"}>{row["ukr"].correction}</div>
                                            {DATA_OBJECT["user"]["login"] !== "root" && row["ukr"].state < 0 ? 0 : row["ukr"].state}

                                            {row["ukr"].blocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (System):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["ukr"].blocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }

                                            {row["ukr"].frontendBlocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (Frontend):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["ukr"].frontendBlocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }
                                        </div>,
                                        onClick: row => this.handleRowClick(row, "ukr")
                                    }
                                ]}
                            />
                        }
                    </Segment>

                </Container>

                {this.state.warehouseState &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.warehouseState} onClose={() => this.setState({warehouseState: false})}>
                        <Modal.Header>Stan magazynowy</Modal.Header>

                        <Modal.Content>
                            <Table compact>
                                <Table.Body>
                                    {this.state.warehouseState.map((item, key) => {
                                        const state = item.state[0];

                                        return (
                                            <Table.Row key={key}>
                                                <Table.Cell width={1}>
                                                    <div style={{
                                                        color: "#787878",
                                                    }}>
                                                        {item.parlour}
                                                    </div>
                                                </Table.Cell>
                                                <Table.Cell width={3}>
                                                    <div style={{
                                                        color: "#787878",
                                                    }}>
                                                        {state.sku}
                                                    </div>
                                                </Table.Cell>
                                                <Table.Cell width={1}>
                                                    <div>
                                                        {state.qty}
                                                    </div>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        </Modal.Content>
                    </Modal>
                }

                {this.state.editConfig &&
                <Modal dimmer={"inverted"} size={"medium"} open={this.state.editConfig} onClose={() => this.setState({editConfig: false})}>
                    <Modal.Header>Warunki magazynowe</Modal.Header>

                    <Modal.Content>
                        <DataPresentation
                            label={"Buffor VGL"}
                            data={this.state.configs[0].bufforVGL}
                            editable={true}
                            onChange={(e) => {
                                this.state.configs[0].bufforVGL = e;
                                this.setState({
                                    refresh: new Date().getTime()
                                })
                            }}
                        />

                        <DataPresentation
                            label={"Buffor Salon VGL TRUE"}
                            data={this.state.configs[0].bufforSalVGLon}
                            editable={true}
                            onChange={(e) => {
                                this.state.configs[0].bufforSalVGLon = e;
                                this.setState({
                                    refresh: new Date().getTime()
                                })
                            }}
                        />

                        <DataPresentation
                            label={"Buffor Salon VGL FALSE"}
                            data={this.state.configs[0].bufforSalVGLoff}
                            editable={true}
                            onChange={(e) => {
                                this.state.configs[0].bufforSalVGLoff = e;
                                this.setState({
                                    refresh: new Date().getTime()
                                })
                            }}
                        />

                        <div className={"wh-conditions-index-buffor-box"}>
                            <div className={"wh-conditions-index-label"}>{TranslationController.translate("Bufor wybranych SKU")}:</div>

                            <div>
                                <CsvImport
                                    onUpdate={(data) => {
                                        return new Promise(resolve => {
                                            const indexBuffor = {}

                                            data.forEach(e => {
                                                if (e.sku && e.sku.length > 2) {
                                                    indexBuffor[e.sku] = e
                                                }
                                            })

                                            this.state.configs[0].indexBuffor = indexBuffor

                                            this.setState({
                                                refresh: new Date().getTime()
                                            })

                                            return resolve()
                                        })
                                    }}
                                />

                                <Button
                                    color='blue'
                                    style={{
                                        marginTop: 5
                                    }}
                                    content={TranslationController.translate("Eksportuj")}
                                    icon='download'
                                    loading={this.state.exporting}
                                    label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                    onClick={() => {
                                        if (this.state.configs[0].indexBuffor) {
                                            exportToFile(
                                                Object.keys(this.state.configs[0].indexBuffor).map(key => this.state.configs[0].indexBuffor[key]),
                                                "uniExport",
                                                ["sku", "bufforTrue", "bufforFalse"],
                                                ["sku", "bufforTrue", "bufforFalse"].map(e => ({
                                                    sku: e,
                                                    bufforTrue: e,
                                                    bufforFalse: e
                                                })),
                                            )
                                        } else {
                                            SnackBar("Brak danych do eksportu", "warning")
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div className={"wh-conditions-index-buffor-box"}>
                            <div className={"wh-conditions-index-label"}>{TranslationController.translate("Czas wysyłki względem magazynów")}:</div>

                            <div className={"wh-conditions-shipping-times-wrapper"}>
                                {[
                                    {
                                      label: "E99",
                                      key: "e99"
                                    },
                                    {
                                        label: "E99 + 157",
                                        key: "e99_157"
                                    },
                                    {
                                        label: "E99 + 157 + salon",
                                        key: "e99_157_parlour"
                                    },
                                    {
                                        label: "IP1",
                                        key: "ip1"
                                    },
                                    {
                                        label: "157",
                                        key: "157"
                                    },
                                    {
                                        label: "K99 + 157",
                                        key: "k99_157"
                                    },
                                    {
                                        label: "N32",
                                        key: "n32"
                                    },
                                    {
                                        label: "Salony",
                                        key: "parlour"
                                    },
                                    {
                                        label: "Niemcy",
                                        key: "de"
                                    },
                                    {
                                        label: "Outlet",
                                        key: "out"
                                    },
                                    {
                                        label: "PRIB",
                                        key: "prib"
                                    },
                                    {
                                        label: "UKR",
                                        key: "ukr"
                                    },
                                    {
                                        label: "VOS",
                                        key: "vos"
                                    },
                                    {
                                        label: "Dodatkowy czas K99",
                                        key: "additional_time"
                                    }
                                ].map((e, k) => {
                                    return (
                                        <div key={k}>
                                            <DataPresentation
                                                label={e.label}
                                                data={this.state.configs[0].shippingTime ? this.state.configs[0].shippingTime[e.key] : 0}
                                                editable={true}
                                                onChange={(val) => {
                                                    if (!this.state.configs[0].shippingTime) {
                                                        this.state.configs[0].shippingTime = {}
                                                    }

                                                    this.state.configs[0].shippingTime[e.key] = parseInt(val);

                                                    this.setState({
                                                        refresh: new Date().getTime()
                                                    })
                                                }}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Modal.Content>

                    <Modal.Actions>
                        <Button onClick={() => this.setState({editConfig: false})} negative>Anuluj</Button>
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Zapisz'
                            onClick={() => this.setConfig()}
                        />
                    </Modal.Actions>
                </Modal>
                }

                {this.state.modalOpened &&
                    <Modal dimmer={"inverted"} size={"mini"} open={this.state.modalOpened} onClose={() => this.setState({modalOpened: false})}>
                        <Modal.Header>Korekta magazynowa</Modal.Header>
                        <Modal.Content>
                            <div className={"states-modal-info"}>
                                <div className={"state-modal-info-text"}>Magazyn: <span>{this.state.modalOpened.warehouse}</span></div>
                                <div className={"state-modal-info-text"}>SKU: <span>{this.state.modalOpened.row.sku}</span></div>
                            </div>
                            <Input
                                onChange={(e) => {
                                    const value = e.target.value
                                    this.setState({
                                        warehouseCorrection: value
                                    })
                                }}
                                placeholder='Korekta...'
                            />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({modalOpened: false})} negative>Anuluj</Button>
                            <Button
                                positive
                                icon='checkmark'
                                labelPosition='right'
                                content='Zapisz'
                                onClick={() => this.setCorrection(this.state.modalOpened.row, this.state.modalOpened.warehouse, this.state.warehouseCorrection)}
                            />
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.dimmer &&
                    <Dimmer active inverted style={{
                        position: "fixed",
                        zIndex: 99999
                    }}>
                        <Loader size='medium'>Proszę czekać... Pobieram stany magazynowe.</Loader>
                    </Dimmer>
                }
            </React.Fragment>
        )
    }
}

export default States
